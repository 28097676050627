
import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';


@Component({
  selector: 'app-modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.scss']
})
export class ModalSpinnerComponent implements OnInit {

  loading: boolean = false;
  modalText!: string;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.isLoading.subscribe((res: boolean) => {
      this.loading = res;
    });
    this.spinnerService.spinnerText.subscribe((res: string) => {
      this.modalText = res;
    });
  }

  ngOnInit(): void {
    this.spinnerService.hideSpinner();
  }

}
