<div class="toolbar" id="header" role="banner">
    <div class="balance-transfer-header container">
        <div class="logo">
            <div class="header-logo">
                <img alt="Citizens Commercial Banking" src="{{citizensLogo}}">
            </div>
            <div class=fdic>
                <span class="fdic-logo fdic-logo-margin"></span>
                <span class="fdic-text fdic-text-margin">FDIC-Insured - Backed by the full faith and credit of the U.S. Government</span>
            </div>
        </div>
    </div>
</div>

<div class="container">
<div class="transfer-form">
     <a href="" click="onBack()" class="back-button cbensightenevent" cbdata-type="link" cbdata-reason="balance-transfer-back" >
        <div class="flex-item">
            <div class="chevron"></div>
            <div>Back</div>
        </div>
    </a>

<div class="balance-transfer-card-title">Make a Transfer</div>
    <div class="row">
      <div class="col-12">
        <app-alert></app-alert>
      </div>
    </div>

<form [formGroup]="balanceTransferForm" class="balance-transfer-form">

    <div class="form-group mat-select-wrapper full-width company-width">
        <mat-label for="company">Company - User ID</mat-label>
        <mat-form-field>
            <mat-select
            [formControl]="this.balanceTransferForm.get('company')"
            (selectionChange)="onCompanyChange()">
              <mat-option *ngFor="let company of companies" [value]="company">{{company.companyId}} - {{company.userId}}</mat-option>
            </mat-select>
         </mat-form-field>
    </div>
    <div class="form-group mat-select-wrapper">
        <mat-label>Transfer From</mat-label>
        <app-account-select
            [data]="fromAccountData$"
            [value]="this.balanceTransferForm.get('transferFrom')?.value"
            (changeValue)="valueChangedFromAccount($event)"
            (changeSearch)="searchAccount('transferFrom', $event)"
          >
        </app-account-select>
        <span>{{this.fromAccountAvailableBalanceMessage}}</span>
    </div>
    <div class="form-group mat-select-wrapper">
        <mat-label>Transfer To</mat-label>
        <app-account-select
            [disabled]="this.disableAccountSignal"
            [data]="toAccountData$"
            [value]="this.balanceTransferForm.get('transferTo')?.value"
            (changeValue)="valueChangedToAccount($event)"
            (changeSearch)="searchAccount('transferTo',$event)" >
       </app-account-select>
        <span> {{this.toAccountAvailableBalanceMessage}} </span>
    </div>
    <div class="form-group mat-select-wrapper">
        <label for="amount">Amount</label>
        <mat-form-field>
        <input matInput  type="text"
        id="amount"
        autocomplete="off"
        formControlName="amount"
        appAmountOnly
        required>
        <mat-error *ngIf="hasError('amount')">
            {{this.balanceTransferForm.get('amount')?.errors?.invalid}}
        </mat-error>
    </mat-form-field>
    </div>
    <div class="form-group mat-select-wrapper mat-datepicker_wrapper">
        <label for="transferDate">Transfer Date</label>
        <mat-form-field>
            <input matInput
            [formControl]="this.balanceTransferForm.get('transferDate')"
            id="transferDate"
            [matDatepicker]="transferDatePicker"
            [matDatepickerFilter]="holidayFilter"
             (dateInput)="onDateInput($event)"
             [min]="minDate"
             [max]="maxDate"
             [disabled]="this.disableOnAccountIsEmptySignal()" required>
            <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transferDatePicker></mat-datepicker>
            <mat-error *ngIf="hasError('transferDate')">
               {{ this.balanceTransferForm.get('transferDate')?.errors?.invalid }}
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group mat-select-wrapper full-width">
        <label for="comment">Comment</label>
        <mat-form-field>
        <input matInput type="text" id="comment"  autocomplete="off" formControlName="comment" maxlength="100">
        <mat-error *ngIf="hasError('comment')">
            {{ this.balanceTransferForm.get('comment')?.errors?.invalid}}
        </mat-error>
        </mat-form-field>
    </div>
</form>
<div class="transfer">
    <button type="button" (click)="onTransferSubmit()" class="btn btn-primary cbensightenevent" cbdata-type="button" cbdata-reason="transfer">Transfer</button>
</div>


</div>
</div>