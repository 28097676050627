import { AlertService } from './../../services/alert.service';
import { Component, effect, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, scan, takeUntil, tap } from 'rxjs/operators';
import { CommonUtil } from '../../utilities/common-util';
import { SpinnerService } from '../../services/spinner.service';

export const CUSTOM_SELECT_CONTROL_VALUE_ACCESSOR: any =  {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AccountSelectComponent),
  multi: true
};
@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrl: './account-select.component.scss',
  providers: [CUSTOM_SELECT_CONTROL_VALUE_ACCESSOR]
})
export class AccountSelectComponent implements OnInit, OnDestroy{
  @Input() disabled = signal(false);
  @Input('data') data$: { subscribe: (arg0: { next: (data: any) => void; }) => any; };
  @Input() value: any;
  @Output() changeValue = new EventEmitter();
  @Output() changeSearch = new EventEmitter();

  onTouch: any = () => {};

  selectCrtl: FormControl = new FormControl('', Validators.required);
  searchCtrl: FormControl = new FormControl();

  subscriptions: Subscription[] = [];
  _options = new BehaviorSubject<any>([]);
  options$ = this._options.asObservable().pipe(
    scan((acc: any, curr: any) => {
      if (!acc || !curr) return [];
      return [...acc, ...curr];
    }, [])
  );

  totalOffset: number = 0;
  offset = 1;
  limit = 10;
  data: any = [];
  public spinnerTitle: string = 'Searching...';

  /** indicate search operation is in progress */
  public searching = false;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  public displaySpinner = signal(false);

  constructor( private spinnerService: SpinnerService,private alertService: AlertService) {
    // Used signal as input to check if the mat select is enabled or disabled
    effect(() => this.disabled() ? this.selectCrtl.disable() : this.selectCrtl.enable());
  }

  ngOnInit() {
    this.searching = true;   
    this.subscriptions.push(this.data$.subscribe({
      next: (res) => {
       
        console.debug('Ingested data changed : ', JSON.stringify(res));
        this.searching = false;
        this.displaySpinner.set(false);
        this.data = CommonUtil.getOrDefault(res?.data, []);
        this.totalOffset = CommonUtil.getOrDefault(res?.totalOffset, 0);
        this.selectCrtl.setValue(this.value);
        if (this.offset <= 1 || this.totalOffset == 0) {
          // reset offset 
          this.offset = 1;
          this._options.next(null);
        }
        console.log(`Ingested data size :  ${this.data.length}`);
        this._options.next(this.data);
      }
    }));

    this.subscriptions.push(
      // listen for search field value changes
      this.searchCtrl.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          tap(() => this.offset = 1),
          takeUntil(this._onDestroy),
          debounceTime(500),
          map(search => this.onSearchChange(search)),
          takeUntil(this._onDestroy)
        ).subscribe({
           next: (value: any) => {
            this.displaySpinner.set(false);
            this.spinnerService.hideSpinner();
            this.searching = false;
            this.options$.subscribe((val: any) => console.log(`New view array contains ${val.length} items`))
          },
          error: (e) => {
            // no errors in our simulated example
            this.searching = false;
            console.error('Failed to fetch the account list');
            // handle error...
          }
        })
    );
  }

  ngOnDestroy(): void {
    console.log(`Destroy or Unsubscribe`);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onChange(event: any): void {
    console.log(`Value On Changed: ${event} ${this.searchCtrl}`);
    this.displaySpinner.set(true);
    this.spinnerService.setText('Please wait...')
    this.spinnerService.showSpinner();
    this.changeValue.emit(event);
  }

  onSearchChange(event: any): void {
    //Check if the event is not empty
    if (!event) {
      return;
    }
    console.log(`Search Changed: ${event}`);

    this.offset = 1;
    const search = {
      searchCriteria: event,
      offset: this.offset
    }
    this.searchEmit(search);
  }

  onReset(): void {
    console.log(`Reset on Select : `);
    this.alertService.hide();
    this.offset = 1;
    this.searchCtrl.reset();
    const search = {
      searchCriteria: '',
      offset: this.offset
    }    
    this.searchEmit(search);
  }

  clearSelection(event: Event): void {
    event.stopPropagation();
    this.onReset();
  }

  getNextBatch(): void {
    this.offset += this.limit;
    const search = {
      searchCriteria: CommonUtil.getOrDefault(this.searchCtrl.value, ''),
      offset: this.offset
    }
    this.searchEmit(search);
  }

  public searchEmit(search: any) {
    this.displaySpinner.set(true);
    this.searching = true;
    this.changeSearch.emit(search);
    this.searching = false;
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1 && o2 && (o1.accountName === o2.accountName && o1.accountNumber === o2.accountNumber);
  }
}
