import { FormControl, Validators } from "@angular/forms";
import { CommonUtil } from "./common-util";

export class ValidatiorUtil extends Validators {

    static dateRequired = { invalid: 'Transfer date is required' };
    static previousDateCheck ={ invalid: 'Transfer date cannot be previous day' }


    static amountRequired = { invalid: 'Amount is required' };
    static amountInvalid =  { invalid: 'Amount entered is invalid' };
    static amountAtleast = { invalid: 'Amount must be atleast 0.01' };
    static amountPattern = /^-?[\d.]+(?:e-?\d+)?$/;  
     /**
     * Validator for the Date field, check input date is not smaller than today's date and is mandatory field
     * @param control form control to validate the element
     * @returns form control with errors object in array
     */
    static dateValidator(control: FormControl) {
        control.markAsTouched();
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);        
        const compareDate: Date = CommonUtil.getOrDefault(control.value, new Date());
        compareDate.setHours(0, 0, 0, 0);
        const previousDayMessage = (compareDate < today) ? ValidatiorUtil.previousDateCheck : null;       
        let errorMessage = !CommonUtil.valueExist(control.value) ? ValidatiorUtil.dateRequired : previousDayMessage ;
        // return null if there's no errors
        control.setErrors(errorMessage);
        return control.errors;
    }

    /**
     * Validator for the Amount field, check amount pattern, the Amount is required and must be atlease 0.01
     * @param control form control to validate the element
     * @returns form control with errors object in array
     */
    static amountValidator(control: FormControl) {
        control.setErrors(null);

        if (control.touched || control.dirty) {
            let errorMessage = !CommonUtil.valueExist(control.value)
                ? ValidatiorUtil.amountRequired
                : ValidatiorUtil.isValidAmountCheck(control);
            control.setErrors(errorMessage);
        }
        return control.errors;
    }

    /**
     * Validate the amount pattern and atleast 0.01 and cannot be zero
     * @param control formControl to get the value and update
     * @returns map value
     */
    static isValidAmountCheck(control: FormControl): any {
        if(!ValidatiorUtil.amountPattern.test(control.value)) {
            return ValidatiorUtil.amountInvalid;
        }
       return (control.hasError('min') || Number(control.value) < 0.01)
                        ?  ValidatiorUtil.amountAtleast
                        : null;                                    
    }
}
