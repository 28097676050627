import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import { ApiService } from './api.service';
import { sharedState } from '@citizens/mfe-shared-state';


interface LINKCREDINFO {
  companyid: string;
  user_disabled: boolean;
  user_locked: any;
  userid: string;
  under_maintenance: boolean;
  applicationName: string;
}


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public ssoIDArr: any[] = [];
  private appName: string;
  public companies: any[] = [];
  public entitledSsoIds : any[];
  public companiesSignal = signal<any[]>([])
  public displayMakeATransferCard = signal(false);
  public linkedCredentialInfo;

  _companies = new BehaviorSubject<any>([]);
  public companyObservable$ = this._companies.asObservable();

constructor(
    private apiService: ApiService
  ){

  }

  /**
   * Get the company objects for a list of sso ids which are having atleast one transfer account
   * @returns Array of Companies
   */
  public getEntitledCompanyIds: any = () => {
    sharedState.getApplicationDataSub('linkedCredentialInfo').subscribe(data => {
      this.linkedCredentialInfo = data;
      this.getCompanyInfo();
      this.retrieveCompanyIdsEntitledByTransfer();
    });
  }

  /**
  * Get the Company and SSO ID information
  */
   private getCompanyInfo() {
    const getlinkCredFromSession = this.linkedCredentialInfo;
    const linkCredInfo = JSON.parse(getlinkCredFromSession);
    if (!linkCredInfo) {
      return false;
    }
      for (let [key, value] of Object.entries(linkCredInfo)) {
        const linkCredsValue = value as LINKCREDINFO;
        const isMMGPS = key.includes('MMGPS');
        const isCashflow = key.includes('CASHFLOW');
        const isCompanyID = linkCredsValue.companyid;
        const isNotDisabled = linkCredsValue.user_disabled === false;
        const isNotLocked = linkCredsValue.user_locked === '' || linkCredsValue.user_locked === false;
        const isUnderMaintenance = linkCredsValue.under_maintenance === false

        if (isCompanyID && isUnderMaintenance && isNotDisabled && isNotLocked && (isMMGPS || isCashflow)) {
          let appName = isMMGPS ? "accessoptima" : 'cashflow'
          linkCredsValue['applicationName'] = appName;
          this.appName = linkCredsValue['applicationName'] = isMMGPS ? 'accessOPTIMA' : 'Cash Flow Essentials';
          this.ssoIDArr.push(key);
          this.companies.push({ ssoId: key, companyId: linkCredsValue.companyid, userId: linkCredsValue.userid, applicationName: appName, applicationTitle: isMMGPS ? 'accessOPTIMA' : 'Cash Flow Essentials' })
        }
      }
      console.log("number of companies :: " + this.companies?.length );

    }


  /**
   * RETRIEVE SSOIDS : Invoke server side REST API and retrieve the SSOIds (Company Ids) which are having accounts
   * @param requestData request body to retrieve the balances
   */
  private retrieveCompanyIdsEntitledByTransfer() {
    const requestData = {
      ssoList: this.companies.map(item => item.ssoId)
    }
    this.apiService.getCompanyIdsEntitledByTransfer(requestData).subscribe({
      next: (response: any) =>   {
        this.entitledSsoIds = response?.data;
        const entitledCompanies = this.companies.filter(obj => this.entitledSsoIds.includes(obj.ssoId));
        console.info("Filtered companyIds with the server side entitled transfer enabled user companyId " + entitledCompanies?.length);
        this._companies.next(entitledCompanies);
      },
      error: (error: any) => {
        console.error("Failed to retrieve entitlement due to " + error?.error?.message);
      }
    });
  }
}
