import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  private subscription: Subscription;
  message: any = '';
  constructor(private alertService: AlertService)  {}


  ngOnInit(): void {
      this.subscription = this.alertService.getMessage().subscribe((message: { text: any; }) => {
        if (!!message.text) {
          this.message = message;
          this.alertService.reset.set(false);
        } else {
          this.hide();
        }
      });

  }

  hide(){
    this.message = undefined;
    this.alertService.disabled.set(false);
    this.alertService.reset.set(true);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
