import { CommonUtil } from './../utilities/common-util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public errorMessage: string = '';
  private baseURL: string = environment.baseApiUrl;
  private cdnURL: string = environment.akamaiContentUrl;
  private HOLIDAY_URI: string = '/v1/holidayDates?creditCurrency=USD&creditCountry=US&debitCurrency=USD&debitCountry=US';
  private TRANSFER_URI: string = '/v1/transferBalance';
  private BALANCES_URI: string = '/v1/balances';
  private ACCOUNTS_URI: string = '/v1/accountList';
  private TRANSFER_ENTITLEMENT_URI: string = '/v1/entitlement/TRANSFER';

  SERVICE_CONFIG_MANAGEMENT: string = 'config/service_config_management.json'
  ERROR_CONFIG_MANAGEMENT: string = 'errors/balance_transfer.json'

  isMobileDevice() {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }

    if (/windows phone/i.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    return false;
  }


  httpHeaders(ssoId: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'SSO_ID': ssoId,
      'universal_Id': sessionStorage.getItem('universalId') || '',
      'logId': sessionStorage.getItem('logId') || ''
    });
  }
  constructor(private httpClient: HttpClient) { }


  searchAccountListAPI(data: any, ssoId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}${this.ACCOUNTS_URI}`, JSON.stringify(data), { headers: this.httpHeaders(ssoId) })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  intiateTransfer(data: any, ssoId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}${this.TRANSFER_URI}`, JSON.stringify(data), { headers: this.httpHeaders(ssoId) })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  balanceAPI(data: any, ssoId: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}${this.BALANCES_URI}`, JSON.stringify(data), { headers: this.httpHeaders(ssoId) })
      .pipe(
        tap(data => console.log('Response Data: ', JSON.stringify(data))),
        catchError(this.errorHandler)
      );
  }

  // inquire by reference id
  holidayAPI(ssoId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.baseURL}${this.HOLIDAY_URI}`, { headers: this.httpHeaders(ssoId) })
      .pipe(map((response: any) => response),
        catchError(this.errorHandler)
      );
  }

  //Fetch the json data from the Akamai CDN
  getDataFromCDN(jsonPath: string): Observable<any> {
    return this.httpClient.get<any>(`${this.cdnURL}${jsonPath}`)
      .pipe(map((response: any) => response),
        catchError(error => {
          console.error(`Error during get cdn data ${jsonPath} request`, error);
          return of({ message: 'ERROR', data: null });
        })
      );
  }

  getCompanyIdsEntitledByTransfer(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.baseURL}${this.TRANSFER_ENTITLEMENT_URI}`, JSON.stringify(data), { headers: this.httpHeaders("")})
      .pipe(
        tap(data => console.log('CompanyIds Entitled By Transfer => Response Data: ', JSON.stringify(data))),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: any) {
    let errorMessage;

    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = {
        status: String(error.error?.status),
        message: error.error?.message
      };
    } else {
      // Get server-side error
      let status = CommonUtil.getOrDefault(error.error?.status, error.status);
      errorMessage = {
        status: String((status === 0 || status === '000' || status === undefined) ? 999 : status),
        message: error.error?.message
      };
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}
