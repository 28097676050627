import { singleSpaPropsSubject } from './../single-spa/single-spa-props';
import { BalanceTransferMainComponent } from './components/balance-transfer-main/balance-transfer-main.component';
import { Component,  ViewChild } from '@angular/core';
import { DynamicCompDirective } from './directives/dynamic-comp.directive';

@Component({
  selector: 'app-balance-transfer-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'balance-transfer';
  currentCompName: string = "";
  customClass: string = "";
  shouldDisplayTheComps: boolean = true;
  @ViewChild(DynamicCompDirective, { static: true }) compRef!: DynamicCompDirective;

  compHash: { name: string, compRef: any }[] = [
    { name: "balance-transfer", compRef: BalanceTransferMainComponent }
  ];

  ngOnInit() {
    singleSpaPropsSubject.subscribe((props: any) => {
      console.log(props);
    });
  }

  injectComponent() {
    if (!this.currentCompName) return;

    const componentObj = this.compHash.find(comp => comp.name === this.currentCompName);
    if (!componentObj) return;

    const viewRef = this.compRef.viewContainerRef;
    viewRef.clear();
    viewRef.createComponent(componentObj.compRef);
  }
}

