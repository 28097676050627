<mat-form-field>
    <mat-select  
      msInfiniteScroll
      (infiniteScroll)="getNextBatch()"
      threshold="20%"
      [complete]="offset >= totalOffset"
      [formControl]="selectCrtl"
      placeholder="Select"
      (valueChange)="onChange($event)"      
      [compareWith]="compareObjects" >
        <mat-option >
            <ngx-mat-select-search 
                ngDefaultControl
                [formControl]="searchCtrl"
                placeholderLabel="Search"
                noEntriesFoundLabel="No entry matches"
                [searching]="searching"                
                disableInitialFocus="true"
                clearSearchInput="true" > 
                <mat-icon ngxMatSelectSearchClear (click)="clearSelection($event)">&times;</mat-icon>          
            </ngx-mat-select-search>
        </mat-option> 
            <mat-option *ngFor="let option of options$ | async" [value]="option">
            {{option.accountNumber}} :  {{option.accountName}}
        </mat-option> 
    </mat-select>
     <mat-error *ngIf="this.selectCrtl.errors">
         Please select account
        </mat-error>
</mat-form-field>