import { CompanyService } from './../../services/company.service';
import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assetURL, environment } from '../../../environments/environment';
import { SpinnerService } from '../../services/spinner.service';
import { BalanceTransferSubmitComponent } from '../balance-transfer-submit/balance-transfer-submit.component';

@Component({
  selector: 'app-balance-transfer-main',
  templateUrl: './balance-transfer-main.component.html',
  styleUrl: './balance-transfer-main.component.scss'
})
export class BalanceTransferMainComponent implements OnInit {

  public header: any = environment.header;

  public submitted = false;
  public loading = false;
  errorModal: boolean = false;
  errorMessage : string | null = null;
  validationPendingMsg: boolean = false;
  displayStyle: string = 'block';
  public validatedInfo: any;
  validationMsg!: string;
  displayCard: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private spinnerService: SpinnerService,
    private companyService: CompanyService,
    private cdr: ChangeDetectorRef
   ) { }


    /**
   * Ng Initialize - Angular life cycle to initialize the component
   */
  ngOnInit(): void {
    this.companyService.getEntitledCompanyIds();
    this.companyService.companyObservable$.subscribe(value => {
      this.companyService.companiesSignal.set(value);
      this.displayCard = value.length ? true : false;
      console.log("Display Make A Transfer Quick Action Enabled : ", this.displayCard);
      if(this.displayCard){
        this.cdr.detectChanges();
        const event = new CustomEvent('displayBalanceTransferCard', { detail : true });
        window.dispatchEvent(event);
        }
    })
  }

  get assetURL() {
    return assetURL;
  }


  onBack(): void {
   this.modalService.dismissAll();
  }

  onBackFromPendingModal() {
    this.validationPendingMsg = false;
    if (this.displayStyle === 'block') {
      this.displayStyle = 'none';
    }
    this.modalService.dismissAll();
  }

  onSubmit(): void {
    this.spinnerService.setText('Please wait...')
    this.spinnerService.showSpinner();
  }

  onclick() {
    console.log('openModal');
    this.modalService.open(BalanceTransferSubmitComponent, { fullscreen: true, windowClass: 'fullscreen-modal' });
  }
}
