import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BalanceTransferMainComponent } from './components/balance-transfer-main/balance-transfer-main.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ModalSpinnerComponent } from './components/modal-spinner/modal-spinner.component';
import { DynamicCompDirective } from './directives/dynamic-comp.directive';
import { BalanceTransferSubmitComponent } from './components/balance-transfer-submit/balance-transfer-submit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, provideNativeDateAdapter} from '@angular/material/core';
import { AlertComponent } from './components/alert/alert.component';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { AccountSelectComponent } from './components/account-select/account-select.component';
import { MatSelectModule } from '@angular/material/select';
import { AmountOnlyDirective } from './directives/amount-only.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService } from './services/alert.service';
import { MatIconModule} from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe } from '@angular/common';
import { CompanyService } from './services/company.service';


@NgModule({
  declarations: [
    AppComponent,
    BalanceTransferMainComponent,
    LoadingSpinnerComponent,
    ModalSpinnerComponent,
    DynamicCompDirective,
    BalanceTransferSubmitComponent,
    AlertComponent,
    AccountSelectComponent,
    AmountOnlyDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    MatSelectModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatIconModule,
    CurrencyPipe
  ],
  providers: [
    HttpClient,
    provideNativeDateAdapter(),
    provideHttpClient(),
    provideAnimationsAsync('noop'),
    AlertService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: AlertService) => () => ds.init(),
      deps: [AlertService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


